import { ref, onMounted, onUnmounted, watchEffect } from "vue";

export function windowService() {
    const isMobile = ref(window.innerWidth <= 768);

    const detectMobile = () => {
        isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
        detectMobile(); // Ensure correct state on load
        window.addEventListener("resize", detectMobile);
    });

    onUnmounted(() => {
        window.removeEventListener("resize", detectMobile);
    });

    return isMobile;
}
