<script setup>
import {onMounted, ref} from 'vue';
import {
    ArrowUpIcon,
    ArrowDownIcon,
    ChatBubbleOvalLeftIcon,
    ArrowPathRoundedSquareIcon,
    BookmarkIcon,
    ShareIcon,
    ChartBarIcon
} from '@heroicons/vue/24/outline';
import moment from "moment";
import axios from 'axios';
import {windowService} from "@/services/windowService.js";

const props = defineProps({
    post: {
        type: Object,
        required: true
    },
    showFullThread: {
        type: Boolean,
        default: false
    }
});

const post = ref({ ...props.post });

const navigateToPost = (post) => {
    console.log('Navigating to post', post);
    window.location = '/feed/' + post.identifier;
};

const loadTwitterEmbed = () => {
    if (window.twttr) {
        window.twttr.widgets.load();
    } else {
        const script = document.createElement("script");
        script.setAttribute("src", "https://platform.x.com/widgets.js");
        script.setAttribute("async", "true");
        document.head.appendChild(script);
    }
};

const upvote = async () => {
    try {
        const isUpvoted = post.value.upvoted;

        // Optimistically update UI
        if (isUpvoted) {
            post.value.upvotes -= 1;
            post.value.upvoted = false;
        } else {
            post.value.upvotes += 1;
            post.value.upvoted = true;

            if (post.value.downvoted) {
                post.value.downvotes -= 1;
                post.value.downvoted = false;
            }
        }

        await axios.post(`/api/feed/${post.value.identifier}/upvote`, {
            vote: isUpvoted ? 0 : 1
        });
    } catch (error) {
        console.error('Error upvoting post:', error);
    }
};

const downvote = async () => {
    try {
        const isDownvoted = post.value.downvoted;

        // Optimistically update UI
        if (isDownvoted) {
            post.value.downvotes -= 1;
            post.value.downvoted = false;
        } else {
            post.value.downvotes += 1;
            post.value.downvoted = true;

            if (post.value.upvoted) {
                post.value.upvotes -= 1;
                post.value.upvoted = false;
            }
        }

        await axios.post(`/api/feed/${post.value.identifier}/downvote`, {
            vote: isDownvoted ? 0 : 1
        });
    } catch (error) {
        console.error('Error downvoting post:', error);
    }
};

onMounted(() => {
    loadTwitterEmbed();
});

const isMobile = windowService();
</script>

<template>
    <div
        class="border-b border-gray-800 p-4 hover:bg-wz-dark transition-colors duration-200 cursor-pointer"
        @click="navigateToPost(post)"
    >
        <div class="flex space-x-3">
            <img :src="post?.user?.thumbnail?.url ?? '/images/default.png'" alt="Profile picture" class="w-12 h-12 rounded-full">
            <div class="flex-1">
                <div class="flex items-center space-x-2" v-if="!isMobile">
                    <span class="font-bold text-white">{{ post.user.name }}</span>
                    <a :href="'/u/' + post.user.username" class="text-wz-gray hover:text-gray-400">@{{ post.user.username }}</a>
                    <span class="text-wz-gray">· {{ moment(post.created_at).fromNow() }}</span>
                </div>
                <div class="flex items-center space-x-2 text-sm" v-else>
                    <span class="font-bold text-white">{{ post.user.name }}</span>
                    <a :href="'/u/' + post.user.username" class="text-wz-gray hover:text-gray-400">@{{ post.user.username }}</a>
                    <span class="text-wz-gray">· {{ moment(post.created_at).fromNow() }}</span>
                </div>
                <p class="mt-2 text-white" v-html="post.content"></p>
                <img
                    v-if="post.image"
                    :src="post.image"
                    :alt="post.content"
                    class="mt-3 rounded-2xl w-full h-auto object-contain"
                />

                <img
                    alt="GIF"
                    v-if="post.gif"
                    :src="post.gif"
                    class="mt-3 rounded-2xl w-full h-auto object-contain"
                />

                <!-- YouTube Video Preview -->
                <div v-if="post?.meta?.youtube" class="mt-3">
                    <iframe
                        :src="'https://www.youtube.com/embed/' + post?.meta?.youtube"
                        class="w-full max-w-lg aspect-video rounded-lg"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                </div>

                <!-- Twitter Embed -->
                <div v-if="post?.meta?.twitter" class="mt-3">
                    <blockquote class="twitter-tweet">
                        <a :href="'https://twitter.com/i/status/' + post?.meta?.twitter"></a>
                    </blockquote>
                </div>

                <div class="flex justify-between mt-4 text-wz-gray">
                    <div class="flex space-x-6">
                        <button class="flex items-center space-x-2 hover:text-green-600 transition-colors" @click.stop>
                            <ChatBubbleOvalLeftIcon class="w-5 h-5" />
                            <span>{{ post.replies }}</span>
                        </button>
                        <button class="flex items-center space-x-2 hover:text-green-500 transition-colors" @click.stop>
                            <ArrowPathRoundedSquareIcon class="w-5 h-5" />
                            <span>{{ post.reposts }}</span>
                        </button>
                        <button :class="{'text-green-600': post.upvoted}" class="flex items-center space-x-2 hover:text-green-600 transition-colors" @click.stop="upvote">
                            <ArrowUpIcon class="w-5 h-5" />
                            <span>{{ post.upvotes }}</span>
                        </button>
                        <button :class="{'text-red-600': post.downvoted}" class="flex items-center space-x-2 hover:text-red-500 transition-colors" @click.stop="downvote">
                            <ArrowDownIcon class="w-5 h-5" />
                            <span>{{ post.downvotes }}</span>
                        </button>
                    </div>
                    <div class="flex items-center space-x-4" v-if="!isMobile">
                        <button class="flex items-center space-x-1 text-wz-gray hover:text-wz-blue transition-colors" title="View Stats" @click.stop>
                            <ChartBarIcon class="w-5 h-5" />
                            <span class="text-sm">{{ post.impressions }}</span>
                        </button>
                        <button class="text-wz-gray hover:text-wz-blue transition-colors" title="Share" @click.stop>
                            <ShareIcon class="w-5 h-5" />
                        </button>
                        <button class="text-wz-gray hover:text-wz-blue transition-colors" title="Bookmark" @click.stop>
                            <BookmarkIcon class="w-5 h-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
